import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

function Faq() {
  return (
    <Layout>
      <SEO
        keywords={[`aluminum profile`, `cnc`, `automation`, `window`]}
        title="FAQ"
      />
      <div className="bg-white">
        <div className="relative z-50 max-w-screen-xl px-4 pt-12 pb-16 mx-auto sm:pt-16 sm:pb-20 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
          <h2 className="text-3xl font-extrabold leading-9 text-gray-900">
            Frequently asked questions
          </h2>
          <div className="pt-10 mt-6 border-t-2 border-gray-100">
            <dl className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <div>
                  <dt className="text-lg font-medium leading-6 text-gray-900">
                    Is my machine supported?
                  </dt>
                  <dd className="mt-2">
                    <p className="text-base leading-6 text-gray-500">
                      We have developed drivers and post-processors for most
                      machines available on the market. If your machine is not
                      supported, we will evaluate the development of this
                      connection at no extra cost to you.
                    </p>
                  </dd>
                </div>
                <div className="mt-12">
                  <dt className="text-lg font-medium leading-6 text-gray-900">
                    We have many different types and brands of machines. Is this
                    a problem?
                  </dt>
                  <dd className="mt-2">
                    <p className="text-base leading-6 text-gray-500">
                      Uni_Link delivers one solution for all machines. All
                      machines will be connected to one automation platform with
                      one CAM programming environment.
                    </p>
                  </dd>
                </div>
                <div className="mt-12">
                  <dt className="text-lg font-medium leading-6 text-gray-900">
                    Can Uni_Link connect to the company&lsquo;s homemade
                    software or ERP?
                  </dt>
                  <dd className="mt-2">
                    <p className="text-base leading-6 text-gray-500">
                      Uni_Link has standard and well documented formats to
                      exchange data. Additionally it is possible to develop a
                      tailor made solution.
                    </p>
                  </dd>
                </div>
              </div>
              <div className="mt-12 md:mt-0">
                <div>
                  <dt className="text-lg font-medium leading-6 text-gray-900">
                    Can we machine plastic, aluminum and steel profiles on the
                    same machine??
                  </dt>
                  <dd className="mt-2">
                    <p className="text-base leading-6 text-gray-500">
                      Yes, as long the steel profile don&#39;t exceed the wall
                      thickness of +/- 5mm. Important are the different types
                      and settings of the tools. Uni_Link engineers have
                      developed a unique tool for machining steel which is
                      available to customers.
                    </p>
                  </dd>
                </div>
                <div className="mt-12">
                  <dt className="text-lg font-medium leading-6 text-gray-900">
                    Can we synchronize double mitre saws and machining centers?
                  </dt>
                  <dd className="mt-2">
                    <p className="text-base leading-6 text-gray-500">
                      Yes, this is possible, barcode labels can be printed. By
                      scanning, the CNC machining center identifies the part and
                      the machining processes. If the machine is equipped with
                      the Uni_Link OnBoard HMI, the part with operations will be
                      visible in 3D.
                    </p>
                  </dd>
                </div>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Faq
